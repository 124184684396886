<template>
    <div class="sorder">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    提交订单
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='base'>
            <div class='top'>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/insurance_top.png' @click='router.push({name:"insurance"})'/>
            </div>
            <div class='title'>基本信息</div>
            <van-cell :value="checkShowAddress?checkShowAddress:'请选择上门地址'" is-link @click='addressShowactive' :value-class='checkShowAddress?"valueColor":""'>
                <template #title>
                    <span class="custom-title">上门地址</span>
                </template>
            </van-cell>
            <van-cell :value="serviceTime ? serviceTime:'请选择服务时间'" is-link @click='showTimer' :value-class='serviceTime?"valueColor":""'>
                <template #title>
                    <span class="custom-title">服务时间</span>
                </template>
            </van-cell>
            <van-cell :value="checkShowpatients?checkShowpatients:'请选择患者信息'" is-link @click='patientsShowactive' :value-class='checkShowpatients?"valueColor":""'>
                <template #title>
                    <span class="custom-title">患者信息</span>
                </template>
            </van-cell>
            <van-cell v-if="examCode == '001'" :value="idCard"  :value-class='"valueColor"'>
              <template #title>
                <span class="custom-title">身份证号码</span>
              </template>
            </van-cell>
          <van-field  v-if="examCode == '001'" :label-class='"valueColor"' v-model="patientMail" label="邮箱" placeholder="请填写真实邮箱用于查验检验结果" input-align="right"/>

        </div>
        <div class='base'>
            <div class='title'>预约服务</div>
            <van-cell :value="tcStr !== ''? tcStr :'请选择套餐'" is-link @click="showPrices" :value-class='tcStr !== ""?"valueColor":""'>
                <template #title>
                    <span class="custom-title">预约套餐</span>
                </template>
            </van-cell>
            <!-- <van-cell value="请选择疾病" is-link>
                <template #title>
                    <span class="custom-title">疾病选择</span>
                </template>
            </van-cell> -->
          <div>
            <div  v-if='exists'>
              <van-cell is-link>
                  <template #title>
                      <span class="custom-title">是否有工具或材料包</span>
                  </template>
                  <template #right-icon>
                      <van-radio-group v-model="checked" direction="horizontal">
                          <van-radio name="1" checked-color="#00C291">有</van-radio>
                          <van-radio name="2" checked-color="#00C291">无</van-radio>
                      </van-radio-group>
                  </template>
              </van-cell>
              <template v-if='checked == "2" && tcStr !== "" && consumptionDetail.length >= 1'>
                  <van-radio-group v-model="pakradio" direction="horizontal">
                      <van-cell value=""  class='radio_cell' v-for='(item,index) in consumptionDetail' :key='index' @click='pakclick(index)'>
                          <template #title>
                              <span class="custom-title">
                                  <van-radio :name="index" checked-color="#00C291"></van-radio>
                                  <span class='title_name'>{{item.pkgName}}x{{item.pkgNum}}({{item.pkgPriceSum}}元)</span>
                              </span>
                          </template>
                      </van-cell>
                  </van-radio-group>

              </template>
            </div>
              <van-field
                  v-model="message"
                  rows="2"
                  autosize
                  type="textarea"
                  maxlength="200"
                  placeholder="如需给护士留言或描述您的疑问、疾病、身体情况（最多200字），请写在此处"
                  show-word-limit
                  />
          </div>
          <div v-if="isJgou">
            <van-checkbox-group v-model="checked_jg" @change="onChangeService">
              <div class="jgou" v-for="(item,index) in promotionServiceList">
                <div class="j_box">
                  <img :src="item.pic" alt="">
                  <div class="j_txt">
                       <div class="j_top"><span class="l_name">{{item.serviceName}}</span> <span class="ch_jg"> <van-checkbox :disabled="isChoseJg" :name="item.ID" checked-color="#00C291" label-position="left" icon-size="20px">加购</van-checkbox></span></div>
                       <div class="zh_p">
                           <span><span class="s_t">组合价</span>￥{{item.addPrice}}.00/次<s>原价￥{{item.price}}.00/次</s></span>
                       </div>
                  </div>
                </div>
              </div>
            </van-checkbox-group>
          </div>
        </div>
        <div class='base' v-if='(servicId == "001" && exists) || (servicId == "016" && exists) || (servicId == "077") || (servicId == "002" && roleCode == "002")'>
            <div class='title'>就诊证明</div>
            <div class='up_cont'>
                <van-uploader :after-read="afterReadChufang" upload-text='上传处方' v-model='chufang' max-count='1' accept="image/*"/>
                <van-uploader :after-read="afterReadYaopin" upload-text='上传药品' style='margin-left:20px' v-model='yaoPin' max-count='1' accept="image/*"/>
            </div>
        </div>
        <div class="" style="margin-left:16px;display: flex;align-items: flex-start;font-size: 14px;margin-top: 10px;" >
          <div style="width:26px;">
            <van-checkbox checked-color="#00C291" shape="square" v-model="checks"></van-checkbox>
          </div>
          <div style="display: flex;flex:1;flex-wrap: wrap">
            <span style="margin-left: 4px">下单即视为同意</span>
            <a style="color:#00C291" @click="toPage('goumaixieyi')">《购买协议》</a>
            <a style="color:#00C291" @click="toPage('luyinxieyi')">《录音信息收集协议》</a>
          </div>

        </div>
        <div style='margin-top:100px;'></div>
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>合计：¥</span>{{totle}}</div>
                <van-button type="success" color='#00C291' class='submit_btn' round  @click='submitOrder()'>提交订单</van-button>
            </div>
        </div>

        <van-popup
            v-model:show="specificationsShow"
            position="bottom"
            round
            closeable
            @click-close-icon="closeModal"
            @click-overlay="closeModal"
            :close-on-click-overlay="false"
            >
<!--            <van-picker-->
<!--                title="请选择套餐"-->
<!--                :columns="packageList"-->
<!--                @confirm="onConfirm"-->
<!--                @cancel="onCancel"-->
<!--                />-->
          <div class='concel_title'>选择套餐</div>
          <div class="p-wrap">
            <div class="money-b">
              <div>服务价格 <span class="yh-text" v-if="stepValue > 1">（原价{{yPrice}}，优惠{{yhPrice}}）</span></div>
              <div class="p-text"><span>￥</span>{{tcPrice}}</div>
            </div>
            <div class="sub-txt">平台护士只提供技术服务,购买数量越多优惠越多</div>
            <div class="tc-box">
<!--              <div class="list l-active">胜多负少的</div>-->
              <div class="list" :class="{ 'l-active' : activeTc == index}" v-for="(item,index) in priceListData" :key="item.code" @click="onTcClick(item,index)">{{ item.pkgType }}</div>


            </div>
            <div class="count-box">
              <div>购买数量<span class="cshu"> (限购10次)</span></div>
              <div><van-stepper v-model="stepValue" max="10" @change="onStepChange" disable-input="true"/></div>
            </div>
          </div>
          <div class='btn_content'>
            <van-button type="success" color='#00C291' class='submit_btn' round block @click='tcOnOk'>确定</van-button>
          </div>
        </van-popup>

        <van-popup
            v-model:show="addressShow"
            closeable
            position="bottom"
            >
            <div class='concel_title'>选择地址</div>
            <div class='address_cont'>
                <div class='address' v-for='(item,index) in addressList' :key='index' @click='checkAddress(item)'>
                    <div>
                        <!-- <van-checkbox v-model="checkAddressId == item.ID?true:false" checked-color='#00C291' icon-size='16px'></van-checkbox> -->
                        <van-checkbox v-model="item.check" checked-color='#00C291' icon-size='16px'></van-checkbox>
                    </div>
                    <div class='address_top'>
                        <div class='provinces'>
                            <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default' v-if='item.ISDEFAULT' />{{item.ADDRESS}}</div>
                            <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit'/> -->
                        </div>
                        <div class='detailed'>
                            {{item.DOORPLATE}}-{{item.ADDRESS_DETAIL}}
                        </div>
                    </div>
                </div>

            </div>
            <div class='btn_content'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='addaddressbtn'>添加新地址</van-button>
            </div>
        </van-popup>


        <van-popup
            v-model:show="patientsShow"
            closeable
            position="bottom"
            >
            <div class='concel_title'>选择患者</div>
            <div class='address_cont'>
                <div class='address' v-for='(item,index) in patientsList' :key='index' @click='checkpatients(item.ID)'>
                    <div>
                        <van-checkbox v-model="item.check" checked-color='#00C291' icon-size='16px'></van-checkbox>
                    </div>
                    <div class='address_top'>
                        <div class='provinces'>
                            <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default'/>{{item.REAL_NAME}} {{item.MOBILE}}</div>
                            <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit'/> -->
                        </div>
                        <div class='detailed'>
                            {{item.SEX?'女':'男'}} {{item.AGE}}岁 {{item.RELATIONSHIP}}
                        </div>
                    </div>
                </div>


            </div>
            <div class='btn_content'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='addbingrenbtn'>添加新患者</van-button>
            </div>
        </van-popup>
        <van-popup
            v-if="!isJgou"
            v-model:show="timeShow"
            closeable
            position="bottom"
            >
            <div class='concel_title'>选择服务时间</div>
            <doorTime @submitTime='submitTime' :diffDays="diffDays"></doorTime>

        </van-popup>
        <van-popup
            v-else
            v-model:show="timeShow"
            position="bottom"
        >
          <van-picker
              ref="timePicker"
              cancel-button-text=" "
              title="请选择服务时间"
              :columns="columnsXj"
              @confirm="onConfirmXj"
              @change="onChangeXj"
          />
        </van-popup>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import loginApi from '@axios/login'
import homeApi from '@axios/home'
import orderApi from '@axios/order'
import axios from 'axios';
import jsonp from '@assets/js/jsonp'
import { Toast } from 'vant';
import moment from "moment";

export default {
  name: 'sorder',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let checked = ref('');
    let isChoseJg = ref(true);//是否可选择加购
    let specificationsShow = ref(false);
    let addressShow = ref(false);
    let patientsShow = ref(false);
    let timeShow = ref(false);

    //虚拟选择套餐列表
    let packageList = ref([]);
    //地址信息
    let addressList = ref([]);
    //患者信息
    let patientsList = ref([]);
    //选择的地址ID
    let checkAddressId = ref('');
    //患者ID
    let patientsId = ref('');
    //患者身份证号
    let patientsCard = ref('');
    //备注
    let message = ref('');
    //邮箱
    let patientMail = ref('');
    //耗材单选
    let pakradio = ref(0);
    let checks = ref(false);
    //城市ID
    const cityCode = ref('')
    //可提前几天下单

    const diffDays = ref(0);

    const pakclick = (index) => {
        pakradio.value = index
    }
    //
    const checked_jg = ref([]);

    let servicId = route.query.code;
    let roleCode=route.query.roleCode;
    let serviceType = route.query.serviceType;
    let scdSvCode = route.query.scdSvCode;
    let examCode = route.query.examCode;

    diffDays.value = route.query.serviceType == '079' ? 4 : 0;//肿瘤筛查提前3天下单
    //护士任务参数
    let Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : ''
    let batchNumber = route.query.batchNumber ?  route.query.batchNumber : ''


    // 时间选择空间start
    const timePicker = ref(null);
    //时间string
    let serviceTimeXj = ref('');
    //开始时间
    let timerStar = ref('');
    let timerEnd = ref('');
    const columnsXj= ref([]);

    const diffDays2 = ref(0);
    diffDays2.value = roleCode == '099' ? 3 : 0; //健康自检多加2天
    const columns1 = [ // 第一列
      {
        values: [
          moment().add(1 + diffDays2.value, 'days').format("YYYY-MM-DD"),
          moment().add(2 + diffDays2.value, 'days').format("YYYY-MM-DD"),
          moment().add(3 + diffDays2.value, 'days').format("YYYY-MM-DD"),
          moment().add(4 + diffDays2.value, 'days').format("YYYY-MM-DD"),
          moment().add(5 + diffDays2.value, 'days').format("YYYY-MM-DD"),
          moment().add(6 + diffDays2.value, 'days').format("YYYY-MM-DD"),
        ],
        defaultIndex: 0,
      },
      // 第二列
      {
        values: ['07:00', '08:00', '09:00'],
        defaultIndex: 0,
      },
      {
        values: ['08:00', '09:00', '10:00'],
        defaultIndex: 0,
      },
    ];
    columnsXj.value=columns1;

    const onConfirmXj = (value, index) => {
      // Toast(`当前值: ${value}, 当前索引: ${index}`);
      let timeStr = '';
      let momentYear = '';
      value.forEach((ele, index) => {
        if (index == 0) {
          timeStr +=ele
          console.log(ele)
          momentYear = moment(ele, 'YYYY-MM-DD').format('YYYY-MM-DD');

        }
        if (index == 1) {
          timeStr +=" " + ele+":00"
          timerStar.value = momentYear + ' ' + ele + ':00';

        }
        if (index == 2) {
          // timeStr += '- '
          timerEnd.value = momentYear + ' ' + ele + ':00';
        }
        // timeStr += ele + ' ';
        //  timeStr = timerStar.value;
      })
      serviceTime.value = timeStr;
      console.log(momentYear)
      console.log(timerStar.value)
      console.log(timerEnd.value)
      timeShow.value = false;
    };
    const showTimer = () => {
      timeShow.value = true;
      tcStr.value = '';
      activeTc.value = 0;
      stepValue.value= 1;
    }
    const onChangeXj = (value, index) => {
      if(columnsXj.value == columns1){
        if (index == 1) {
          let colindex = timePicker.value.getColumnIndex(1)//对应列的下标
          if (colindex == 0) {
            timePicker.value.setColumnValues(2, ['08:00', '09:00', '10:00'])
          }
          if (colindex == 1) {
            timePicker.value.setColumnValues(2, ['09:00', '10:00'])
          }
          if (colindex == 2) {
            timePicker.value.setColumnValues(2, ['10:00'])
          }
        }
      }else{
        if (index == 1) {
          let colindex = timePicker.value.getColumnIndex(1)//对应列的下标
          timePicker.value.setColumnIndex(2,colindex)
        }
        if (index == 2) {
          let colindex = timePicker.value.getColumnIndex(2)//对应列的下标
          timePicker.value.setColumnIndex(1,colindex)
        }
      }

    };
    //时间选择空间end
    //栏目（备注、耗材选项）是否存在
    let   exists = ref(false);
    //血检加购是否存在
    let   isJgou = ref(false);
    //
    const logBufferWait = () => {
      //居家康复052  母婴护理2
       if(["052","2","079"].includes(serviceType)){
         exists.value = false;
         return;
       }
       //血检套餐
       if(servicId == '002' && roleCode == '011'){
         exists.value = false;
         isJgou.value = true;
         return;
       }
       //
      //罗特西普打针预约单独处理
      if(servicId == '077' && roleCode == '002'){
        exists.value = false;

        return;
      }
      if(roleCode == '099'){ //肥胖基因那一类
        exists.value = false;
        isJgou.value = true;
       // diffDays2.value = 2; // 多推后2天下单
        return;
      }
       exists.value = true;
    }
    logBufferWait();


   //获取服务城市数据
    const serviceCityList =  ref([]);
    const getServiceCityData = (firSvCode,roleCode,scdSvCode) => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'serviceCity',
        _from : 'h5',
        token : store.state.token,
        userid : store.state.uid + '',
        login_userId_base : store.state.uid + '',
        roleCode:roleCode,
        firSvCode:firSvCode,
        scdSvCode:scdSvCode,
        version : store.state.version,
        newversion : store.state.newversion,
      }))
      formData.append('encryption',false)
      orderApi.orderAction(formData).then((res)=> {
        serviceCityList.value  =  res.data;
      })

    }
    getServiceCityData(servicId,roleCode,scdSvCode);

    //获取加购服务
    const promotionServiceList = ref ([]);
    const getPromotionService = (firSvCode,roleCode,scdSvCode) => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'promotionService',
        _from : 'h5',
        token : store.state.token,
        userid : store.state.uid + '',
        login_userId_base : store.state.uid + '',
        roleCode:roleCode,
        firSvCode:firSvCode,
        scdSvCode:scdSvCode,
        cityCode:cityCode.value,
        version : store.state.version,
        newversion : store.state.newversion,
      }))
      formData.append('encryption',false)
      orderApi.orderAction(formData).then((res)=> {
        console.log('------------')
        console.log(res);
        promotionServiceList.value =res.data;
      })
    }


    // 加购套餐切换
    const jgTotal = ref (0);//加购套餐总价
    const onChangeService = () => {
      console.log('---------')
      console.log(checked_jg.value)
      console.log(addServiceList.value)
      console.log('---------')
      let totle = 0;
      addServiceList.value.forEach(val => {
            totle +=parseInt(val.addPrice)
      })
      console.log(totle);
      jgTotal.value = totle;
    }

     //过滤出增加的加购套餐
    const addServiceList = computed(()=> {
      let addArray=[];
        promotionServiceList.value.forEach((ele)=> {
           if(checked_jg.value.includes(ele.ID)){
             addArray.push(ele);
           }
        })
      return addArray;
    })
console.log(jgTotal.value)




    const getAdress = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectAddressByUserId',
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid + '',
            login_userId_base : store.state.uid + '',
            _validate : '1',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            res.data.forEach(element => {
                if(element.ISDEFAULT){
                    checkAddressId.value = element.ID
                    element.check = true
                }else{
                    element.check = false
                }
            });
            addressList.value = res.data;
            setCityCody();
        })
    }

    let checkShowAddress = computed(()=>{
        let str = '';
        addressList.value.forEach((ele)=>{
            if(ele.ID == checkAddressId.value){
                cityCode.value = ele.CITYCODE
                str = ele.DOORPLATE + '-' + ele.ADDRESS_DETAIL
            }
        })
      if(cityCode.value){
        getPromotionService(servicId,roleCode,scdSvCode);
      }
        return str;
    })

    let checkShowpatients = computed(()=>{
        let str = '';
        patientsList.value.forEach((ele)=>{
            if(ele.ID == patientsId.value){
                str = ele.REAL_NAME + '/'
                if(ele.SEX){
                    str += '女/'
                }else{
                    str += '男/'
                }

                str += ele.AGE;
                str += '/';
                str += ele.RELATIONSHIP
            }
        })
        return str;
    })

    let idCard = computed (() => {
      let str = '';
      patientsList.value.forEach((ele)=>{
        if(ele.ID == patientsId.value){

          str += ele.IDCARD_NO
        }
      })
      return str;
    })




    const checkAddress = (item) => {
      console.log(item)
        checkAddressId.value = item.ID;
        cityCode.value = item.CITYCODE;
        addressList.value.forEach((ele)=>{
            if(ele.ID == item.ID){
                ele.check = true
            }else{
                ele.check = false
            }


        })
        addressShow.value = false;
        setCityCody();
    }


    const checkpatients = (id) => {
        patientsId.value = id;
        patientsList.value.forEach((ele)=>{
            if(ele.ID == id){
                ele.check = true
            }else{
                ele.check = false
            }
        })
        patientsShow.value = false;
    }
    getAdress();

    let serviceTime = ref('');
    //是否存在夜间服务费
    let hasFee = ref(false);

    const submitTime = (time) => {
        console.log(time)
        timeShow.value = false;
        serviceTime.value = time.time
        hasFee.value = time.hasFee
    }

    const getPatients = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectPatientInfoById',
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid + '',
            login_userId_base : store.state.uid + '',
            _validate : '1',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            res.data.forEach(element => {
                if(element.ISDEFAULT){
                    patientsId.value = element.ID
                    patientsCard.value = element.IDCARD_NO
                  console.log('345',element)
                    element.check = true
                }else{
                    element.check = false
                }
            });
            patientsList.value = res.data;
        })
    }

    let packagerealList = ref([]);
    let packcheckIndex = ref('');



    const  onConfirm = (value, index) => {
        // packcheckIndex.value = index;
        specificationsShow.value = false;
        isChoseJg.value = false;//解除加购的禁用

        //获取当前服务需要的耗材包
        consumption();
    }
    const onCancel = () => {
      specificationsShow.value = false;
    }


    let consumptionDetail = ref([]);
    const consumption = () => {
        // let times = 1;
        // if(packagerealList.value[packcheckIndex.value].times.split(' ').length > 1){
        //     times = packagerealList.value[packcheckIndex.value].times.split(' ')[1]
        // }else{
        //     times = packagerealList.value[packcheckIndex.value].times
        // }
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getServiceConsumPkg',
            role_code : route.query.roleCode,
            firSvCode : route.query.code,
            scdSvCode : "",
            times : stepValue.value+''
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            console.log(res);
            consumptionDetail.value = res.data;
        })
    }



    const priceListData = ref([]);
    const activeTc = ref(0);
    const  tcPrice= ref(0);
    const  tcName= ref('');
    const  tcCode= ref('');
    const  danjia= ref(0);
    const  stepValue= ref(1);
    const  yhPrice= ref('');
    const  yPrice= ref('');
    const getPriceTc = ()=> {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'calculatePrice',
        source : 'h5',
        price:danjia.value,
        times:stepValue.value,
      }))
      formData.append('encryption',false)
      loginApi.loginAction(formData).then((res)=>{
        console.log('daze',res)
        yhPrice.value = res.data.yhPrice;
        yPrice.value = res.data.yPrice;
        tcPrice.value = res.data.price;
      })
    }

    const selectNurseSerPrice = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'selectNurseSerPrice',
        source : 'h5',
        version : store.state.version,
        newversion : store.state.newversion,
        role_code : route.query.roleCode,
        firSvCode : route.query.code,
        city_code : cityCode.value + '',
        addressId:checkAddressId.value + '',
        serviceTimeStart:serviceTime.value,
        serviceTimeEnd:serviceTime.value,
        scdSvCode:route.query.scdSvCode
      }))
      formData.append('encryption',false)
      loginApi.loginAction(formData).then((res)=>{
        console.log('tc',res)

        priceListData.value = res.data;
        tcPrice.value = res.data[0].value
        tcName.value = res.data[0].pkgType
        tcCode.value = res.data[0].code
        danjia.value = res.data[0].value
        stepValue.value = res.data[0].times
      })
    }

    const onTcClick =(item,index) => {
      console.log('item',item)
      activeTc.value = index;
      // tcPrice.value = item.value;
      danjia.value = item.value;
      tcName.value = item.pkgType;
      tcCode.value = item.code;
      getPriceTc()
    }
    const onStepChange = (value) => {
      console.log('value',value);
      stepValue.value = value;
      getPriceTc();
    }

    const tcStr = ref('')
    const tcOnOk =() => {
      tcStr.value = `￥${tcPrice.value}/${stepValue.value}次 (${tcName.value})`;
      activeTc.value = 0;
      // stepValue.value = 1;
      // specificationsShow.value =false;
      console.log( tcStr.value)
      console.log('tcCode', tcCode.value)
      onConfirm();
    }
    const closeModal = () => {
      console.log('关闭');
      tcStr.value = ""
       stepValue.value = 1;
       activeTc.value = 0;
    }
    const getPackageList = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectPrice',
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid,
            login_userId_base : store.state.uid,
            _validate : '1',
            version : store.state.version,
            newversion : store.state.newversion,
            role_code : route.query.roleCode,
            service_code : route.query.code,
            title_code : '',
            city_code : '131',
            serviceTimeStart:serviceTime.value,
            scdSvCode:route.query.scdSvCode
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            let arr = [];
            res.data.forEach((ele)=>{
               // arr.push(ele.value + ele.unit + '/' + ele.times + '次')
              let obj = {

              }
              if(ele.yhPrice == '0'){
                arr.push(ele.h5SetMealDesc)
              }else{
                arr.push(ele.h5SetMealDesc + `  (优惠￥${ele.yhPrice}元)`)
              }
            })
            packageList.value = arr;
            packagerealList.value = res.data;
        })
    }

    getPatients();

    //点击显示套餐价格
    const showPrices =()=> {
      if(!checkAddressId.value){
        Toast('请选择服务地址');
        return false;
      }
      if(!serviceTime.value){
        Toast('请选择服务时间');
        return false;
      }
      // getPackageList();
      selectNurseSerPrice();
      specificationsShow.value = true;

    }

    let chufang = ref([]);
    let yaoPin = ref([]);


    const afterReadChufang = (file) => {
      // 此时可以自行将文件上传至服务器
        console.log(file);
        let formData = new FormData();
        formData.append('file',file.file)

        homeApi.imgUpload(formData).then((res)=>{
            chufang.value[0] = {
                url : 'https://file.yihu365.cn' + res.message
            };
        })
    };


    const afterReadYaopin = (file) => {
      // 此时可以自行将文件上传至服务器
        console.log(file);
        let formData = new FormData();
        formData.append('file',file.file)

        homeApi.imgUpload(formData).then((res)=>{
            yaoPin.value[0] = {
                url : 'https://file.yihu365.cn' + res.message
            };
        })
    };


    let totle = computed(()=>{
        // let sumtotle = 0;
        // if(packcheckIndex.value !== ''){
        //     sumtotle += parseFloat(packagerealList.value[packcheckIndex.value].value)
        // }
        // if(checked.value == 2 && consumptionDetail.value[pakradio.value]){
        //
        //     sumtotle += parseFloat(consumptionDetail.value[pakradio.value].pkgPriceSum)
        //
        // }
        // if(hasFee.value){
        //    // sumtotle += 50//添加服务费
        //     if(packcheckIndex.value !== ''){
        //         let times= packagerealList.value[packcheckIndex.value].times.replace(/[^0-9]/ig,"");
        //         sumtotle += (50 * parseInt(times))
        //     }
        // }
        // sumtotle +=jgTotal.value;//加购的累加
        // return sumtotle

      let sumtotle = 0;
      if(tcStr.value !== ''){
          sumtotle += parseFloat(tcPrice.value)
      }
      if(checked.value == 2 && consumptionDetail.value[pakradio.value]){

          sumtotle += parseFloat(consumptionDetail.value[pakradio.value].pkgPriceSum)

      }
      if(hasFee.value){
         // sumtotle += 50//添加服务费
          if(tcStr.value !== ''){
              sumtotle += (50 * parseInt(stepValue.value))
          }
      }
      sumtotle +=jgTotal.value;//加购的累加
      return sumtotle;
    })
    //选中地址的城市code
    let citycode = '';
    const setCityCody = () => {
        let active_adress = {}
        addressList.value.forEach((ele)=>{
            if(ele.ID == checkAddressId.value){
                active_adress = ele;
            }
        })
        jsonp(`https://api.map.baidu.com/reverse_geocoding/v3/?ak=uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q&output=json&location=${active_adress.LATITUDE},${active_adress.LONGITUDE}&callback=baiduCallBack`).then((res)=>{
            console.log(res.result.cityCode)
            citycode = res.result.cityCode;
        })
    }


    //检查用户是否进行了所有操作
    const checkform = () => {
        if(!checkAddressId.value){
            Toast('请选择地址');
            return false;
        }
        if(!serviceTime.value){
            Toast('请选择服务时间');
            return false;
        }
        if(!patientsId.value){
            Toast('请选择患者');
            return false;
        }
        if(examCode == '001' && !patientMail.value){
          Toast('请输入邮箱');
          return false;
        }
        if(tcStr.value == ''){
            Toast('请选择套餐');
            return false;
        }
        if(exists.value){
          console.log('exists.value',exists.value)
          if(!checked.value){
            Toast('请选择是否有工具包');
            return false;
          }
        }


        if((servicId == "001" && exists) || (servicId == "016" && exists) || (servicId == "077") || (servicId == "002" && roleCode =="002")){
            console.log(chufang.value[0])
            if(!chufang.value[0] || !yaoPin.value[0]){
                Toast('请填处方和药品证明');
                return false;
            }
        }

        if(!checks.value){
          Toast("请先勾选购买协议")
          return false;
        }
        if(cityCode.value){
           Toast(cityCode.value)
        }
        return true;
    }


    const submitOrder = () => {
        //页面操作参数整理

        let check = checkform();

        if(!check){
            return;
        }

        let formData = new FormData();
        let active_adress = {}
        addressList.value.forEach((ele)=>{
            if(ele.ID == checkAddressId.value){
                active_adress = ele;
            }
        })
        let patientstel = '';
        patientsList.value.forEach((ele)=>{
            if(ele.ID == patientsId.value){
               patientstel = ele.MOBILE;
            }
        })

        // let times =packagerealList.value[packcheckIndex.value].times.replace(/[^0-9]/ig,"")
         //耗材包相关逻辑
       const pkgId = ()=> {

           if(servicId == '077' && roleCode == '002'){ //单独处理 罗特西普打针
              return consumptionDetail.value[0].pkgId +''
           }
            if(examCode == '001') { //001 表示轻体检相关产品 轻体检没有耗材
              return '';
            }else{
              return  checked.value != 2?'' : (consumptionDetail.value.length > 0 ? consumptionDetail.value[pakradio.value].pkgId + '' : '')
            }
       }
      const pkgNum = ()=> {
        if(servicId == '077' && roleCode == '002'){//单独处理 罗特西普打针
          return consumptionDetail.value[0].pkgNum+''
        }
        if(examCode == '001') { //001 表示轻体检相关产品 轻体检没有耗材
          return '';
        }else{
          return checked.value != 2?'': (consumptionDetail.value.length > 0 ? consumptionDetail.value[pakradio.value].pkgNum + '' : '')
        }
      }

        // uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q
        formData.append('data',JSON.stringify({
            function : 'subscribe',
            token : store.state.token,
            userid : store.state.uid,
            userName : store.state.uid,
            targetUserId : "",
            roleType : route.query.roleCode,//目标用户ID
            subService: exists.value ? '' : scdSvCode,
            service : route.query.code,
            orderType : '1',
            price : totle.value + '',
            description : message.value,
            longitude: active_adress.LONGITUDE,//经度  此处少经纬度
            latitude: active_adress.LATITUDE,//纬度
            // serviceTimeStart: !isJgou.value ? serviceTime.value : timerStar.value,//预约服务开始时间
            serviceTimeStart: serviceTime.value,//预约服务开始时间
            // serviceTimeEnd: !isJgou.value ? serviceTime.value : timerEnd.value,//预约服务结束时间
            serviceTimeEnd: serviceTime.value ,//预约服务结束时间
            address : checkAddressId.value + '',
            payStatus : '-1',
            checkStatus : '0',
            patientArchivesId : patientsId.value + '',
            registrationIdCard : patientsCard.value + '',
            sid : store.state.sid,
            cityCode : citycode + '',
            professionCode : tcCode.value,
            titleCode:  tcCode.value,
            picture1 : '',
            picture2 : '',
            times : stepValue.value + '',
            needTools : checked.value == 2?'1':'0',
            isHasTool : checked.value == 2?'0':'1',
            isHasMedicina : '',
            offerPrice : totle.value + '',
            addedServicePrice : '',
            prescriptionPic : chufang.value[0]?chufang.value[0].url:'',
            medicinePic : yaoPin.value[0]?yaoPin.value[0].url:'',
            casePic : '',
            liveCondPic : '',
            patientMail : examCode == '001' ? patientMail.value : '',
            //耗材包相关
            pkgId : pkgId(),
            pkgNum : pkgNum(),
            //夜间费用
            extraPrice : hasFee.value ? (50 * parseInt(stepValue.value)) + '' :'',
            extraPaymentType : hasFee.value?'2':'0',//费用为0就是空 不为0就是2
            patientMobile : patientstel + '', //患者电话

            //血检加购套餐
            addServiceList:addServiceList.value,
            _from : 'h5',
            version : store.state.version,
            newversion : store.state.newversion,
            source : 'h5_users_002',
            jumpMark :  store.state.jumpMark,

           //护士任务参数
            Recommenduserphone: store.state.Recommenduserphone + '',
            batchNumber: store.state.batchNumber + ''
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{

         console.log(res)
            router.push({
                name : 'pay',
                query : {
                    fee : totle.value,
                    orderId : res.data.orderId,
                    roleCode : route.query.roleCode,
                    serviceCode : route.query.code,
                }
            })
        })
    }



    const addaddressbtn = () => {
        addressShow.value = false;
        router.push({
            name : 'addAddress'
        })
    }


    const addbingrenbtn = () => {
        patientsShow.value = false;
        router.push({
            name : 'addPatients'
        })
    }

    const addressShowactive = () => {
        getAdress();
        addressShow.value = true;
    }

    const patientsShowactive = () => {
        getPatients();
        patientsShow.value = true;
    }
    const toPage =(url)=> {
      router.push({
        name: url
      })
    }

    return {
        toPage,
        addressShowactive,
        patientsShowactive,
        addbingrenbtn,
        addaddressbtn,
        pakclick,
        getServiceCityData,
        serviceCityList,
        getPromotionService,
        promotionServiceList,
        onChangeService,
        jgTotal,//加购价格
        serviceTimeXj,
        columnsXj,
        onConfirmXj,
        onChangeXj,
        timePicker,
        addServiceList,
        cityCode,
        pakradio,
        servicId,
        serviceType,
        diffDays,
        examCode,
        patientMail,
        idCard,
        submitOrder,
        totle,
        packagerealList,
        yaoPin,
        chufang,
        afterReadYaopin,
        consumptionDetail,
        packcheckIndex,
        onCancel,
        onConfirm,
        isChoseJg,
        exists,
        isJgou,
        checked_jg,//加购
        packageList,
        checkShowpatients,
        checkpatients,
        patientsList,
        serviceTime,
        submitTime,
        checkShowAddress,
        checkAddress,
        showPrices,
        checkAddressId,
        addressList,
        timeShow,
        patientsShow,
        addressShow,
        specificationsShow,
        checked,
        router,
        afterReadChufang,
        message,
        roleCode,
        route,
      priceListData,
      activeTc,
      danjia,
      tcPrice,
      onTcClick,
      stepValue,
      yhPrice,
      yPrice,
      tcStr,
      tcOnOk,
      closeModal,
      onStepChange,
      checks,
      showTimer
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep('.base .van-cell__title'){
  flex-grow: 1!important;
  color:#6DD0FF;
}
    .btn_content{
        padding:30px;
    }
    .top{
        // padding:25px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0 20px;
        img{
            width: 100%;
        }

    }
    .address_cont{
        padding:30px;
        padding-top:0;
        max-height: 800px;
        overflow-y: scroll;
        .address{
            padding:0 15px;
            background:#fff;
            border-radius: 10px;
            margin-bottom:25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EDEDED;
            .address_top{
                padding-top:40px;
                padding-bottom:25px;
                padding-left:25px;
                width: 100%;
            }
        }
         .provinces{
            display: flex;
            justify-content: space-between;
            // align-items: center;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
            padding-right:30px;
            &>div{
                display: flex;
                // align-items: center;
            }
            .default{
                width: 60px;
                height: 30px;
                margin-right:20px;
                position: relative;
                top:5px;
            }
            .icon_edit{
                width: 34px;
                height: 34px;
                position: relative;
                top:5px;
            }
        }
    }
    .concel_title{
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 100px;
        height: 100px;
        border-bottom: 1px solid #EDEDED;
    }
    .specifications_cont{
        padding:30px;
        .specifications_btn{
            padding-top:48px;
            padding-bottom:24px;
        }
        .specifications{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            padding:25px 0;
            border-bottom: 1px solid #EDEDED;
            .specifications_num{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .chose_specifications{
                display: flex;
                flex-wrap: wrap;
                div{
                    padding:10px 25px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-top:18px;
                    margin-right:15px;
                }
                .active_specifications{
                    border:1px solid #00C291;
                    background: #F4FFFA;
                    color:#00C291;
                }
            }
        }

        .specifications_top{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            border-bottom: 1px solid #EDEDED;
            padding-bottom:30px;

            .money{
                color:#FF6161;
                font-size: 40px;
                font-weight: 500;
                .small{
                    font-size:28px;
                    margin:0 5px;
                }

            }
            .goods{
                width: 170px;
                height: 170px;
                border-radius: 10px;
                margin-right:20px;
            }
        }

    }
    .sorder{
        height: 100%;
        background:#f0f0f0;
        overflow: scroll;
    }
    .base{
        // padding:20px 35px;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        margin-top:20px;
        margin-left:30px;
        margin-right:30px;

        ::v-deep(textarea){
            padding:15px;
            background: #F7F7F7;
            border-radius: 10px;
        }
        ::v-deep(.van-uploader__upload){
            border:1px dashed #999;
        }


        .title{
            font-size: 34px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #313131;
            padding:20px 35px;
        }
      .jgou{
        padding:0 30px 0 30px;
        font-size: 28px;
        color:#313131;
        .j_box{
          display: flex;
          border-bottom:1px solid #ebedf0;
          padding-bottom: 30px;
          padding-top: 30px;
          .j_txt{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .j_top{
              display: flex;
              justify-content: space-between;
              font-size: 32px;
              span{
                display: block;
              }
              .l_name{
                width: 72%;
              }
              .ch_jg{
                  color:#999999;
                  font-size: 24px;
                  margin-right: 20px;
                ::v-deep(.van-radio__label){
                  color:#999999;
                }
                ::v-deep(.van-radio__label--left){
                  margin-right: 8px;
                }
              }
            }
            .zh_p{
              color:#FF6161;
              .s_t{
                font-size: 24px;
              }
              s{
                font-size: 24px;
                color:#999999;
                margin-left: 20px;
              }
            }
          }
          img{
            width: 120px;
            height:120px;
            display: block;
            margin-right: 20px;
          }
        }
      }
    }
    .up_cont{
        padding:20px 30px;
        display: flex;
        justify-content: flex-start;
    }
    .custom-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title_name{
            position: relative;
            top:4px;
        }
    }
    .btn_btm_cont{
        width: 100%;
        position: fixed;
        bottom: 0;
        left:0;
        padding:20px 0;
        display: flex;
        justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top:1px solid #f5f5f5;
        .btn_inner{
            padding:0 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .order_btn{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding:0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left:20px;
        }
        .money{
            margin-right:40px;
            color:#FF6161;
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }
        .small{
            font-size:28px;
            margin:0 5px;
        }
        .submit_btn{
            width: 200px;
            height: 80px;
            line-height: 80px;
            font-size: 28px;
            white-space: nowrap;
        }
    }
    .detailed{
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        margin-top:18px;
    }
    .radio_cell{
        ::v-deep(.van-cell__title){
            width:600px!important;
        }
        ::v-deep(.van-radio){
            margin-right:20px;
            width:50px;
        }
        ::v-deep(.van-cell__value){
            flex: 0!important;
        }
    }

    .p-wrap{
      color:#666666;
      box-sizing: border-box;
      padding:26px 40px 0;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 32px;
      .sub-txt{
        font-size: 24px;
        margin-bottom: 30px;
        color:#999999;
      }
      .money-b{
        display: flex;
        justify-content: space-between;
        .yh-text{
          font-size: 24px;
          color:#999999;
        }
      }
      .count-box{
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0;
        .cshu{
          font-size: 26px;
          color:#999999;
        }
      }
      .tc-box{
        display: flex;
        flex-wrap: wrap;
        .list{
          font-size: 30px;
          background-color: #f1f2f6;
          box-sizing: border-box;
          padding: 8px 24px;
          border-radius: 28px;
          margin-right: 30px;
          margin-bottom: 24px;
        }
        .l-active{
          background-color: #00C291;
          color:#FFFFFF;
        }
      }
      .p-text{
        color:#00C291;
        font-size: 40px;
        span{
          font-size: 24px;
        }
      }
    }

</style>
